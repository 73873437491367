/* Main search box container */
.ace_search {
  --font-size: 14px;
  --base-color: #f8f8f2;
  --border-radius: 4px;
}
.ace_search {
  background-color: #282a36; /* Dracula background */
  border: 1px solid #44475a; /* Dracula current line */
  border-top: 0 none;
  padding: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.ace_search_form {
  display: flex;
  align-items: center;
  gap: 5px;
}
/* Search input field */
.ace_search_field {
  background-color: #44475a; /* Dracula selection */
  border: 1px solid #6272a4; /* Dracula comment */
  border-radius: 4px;
  color: #f8f8f2; /* Dracula foreground */
  padding: 4px 8px;
  height: 30px;
  box-sizing: border-box;
  font-size: var(--font-size);
  width: 200px;
}

/* Buttons in search box */
.ace_searchbtn,
.ace_replacebtn {
  background-color: #44475a; /* Dracula selection */
  border: 1px solid #6272a4; /* Dracula comment */
  border-radius: 4px;
  color: #f8f8f2; /* Dracula foreground */
  /* margin: 0 2px; */
  box-sizing: border-box;
  height: 30px;
  cursor: pointer;
  font-size: var(--font-size);
  display: flex;
  align-items: center;
}
/* hide find all button */
.ace_searchbtn[action="findAll"] {
  display: none;
}

.ace_searchbtn:hover,
.ace_replacebtn:hover {
  background-color: #6272a4; /* Dracula comment */
  border-color: #bd93f9; /* Dracula purple */
}

/* Close button */
.ace_searchbtn_close {
  background-color: #ff5555; /* Dracula red */
  color: #f8f8f2; /* Dracula foreground */
  border: none;
  border-radius: 4px;
  padding: 0;
}

.ace_searchbtn_close:hover {
  background-color: #ff6e6e; /* Lighter red */
}

.ace_button {
  font-size: var(--font-size);
  border: 1px solid var(--base-color);
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  margin: 0;
  color: var(--base-color);
}
/* Regular expression button and other toggles when active */
.ace_button.checked {
  background-color: #50fa7b; /* Dracula green */
  color: #282a36; /* Dracula background */
  border-color: #50fa7b;
}

/* Replace form */
.ace_replace_form {
  display: flex;
  align-items: center;
  gap: 5px;
}
/* .ace_replace_form .ace_search_field {
  background-color: #44475a;
  border: 1px solid #6272a4;
  color: #f8f8f2;
} */

/* Search options */
.ace_search_options {
  margin: 4px 0;
  display: flex;
  align-items: center;
  color: #f8f8f2;
  gap: 5px;
}
.ace_search_counter {
  font-size: var(--font-size);
}

/* Search counter */
.ace_search_counter {
  color: #6272a4; /* Dracula comment */
}

.ace_search_options :nth-child(3) {
  margin-left: auto;
}
/* Button text */
.ace_searchbtn span {
  color: #f8f8f2;
}

/* Focus states */
.ace_search_field:focus {
  border-color: #bd93f9; /* Dracula purple */
  outline: none;
  box-shadow: 0 0 0 2px rgba(189, 147, 249, 0.3);
}

.ace_search_form.ace_nomatch {
  outline: none;
}
