.full-screen {
  background: #171717;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  z-index: 1300;
}
