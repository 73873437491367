
.sdp {
    outline: none !important;
    background-color: #171717 !important;
    --font: 'Mulish', sans-serif !important;
    --theme-color: #ff914c !important; 
    
}
.sdp--text.sdp--month-name:focus,.sdp--square-btn__shadowed:focus{
    box-shadow:  #262626 0px 0px 0px 2px !important;
}
.sdp:focus{
    box-shadow:  #262626 0px 0px 0px 2px !important;
}
.sdp--square-btn.sdp--date-btn:focus{
    box-shadow:  #262626 0px 0px 0px 2px !important;
}
.sdp--square-btn{ 
    background-color: #262626 !important;
    color:rgba(239,237,239,0.87);
}
.sdp--square-btn.sdp--date-btn__selected {
    background-color: #ff914c !important;
    color:#171717;
}
.sdp--text__inactive{
    color: rgba(239,237,239,0.38) !important;
}