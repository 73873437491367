/* material icons  */
:root {
  --dd-bg: #262626;
  --dd-bg-accent: #484a4d;
  --dd-text-color: #dadce1;
  --dd-nav-size: 60px;
  --dd-border-radius: 8px;
  --dd-border: 1px solid #474a4d;
  --dd-speed: 500ms;
}

/* tiered list */
.tierlist,
.tierlist ul {
  list-style: none;
}
.tierlist > li {
  text-decoration: none;
}
.tierlist__expand {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 300ms ease;
}
.tierlist__expand__expanded {
  transform: rotate(180deg);
}
.tierlist__listitem {
  position: relative;
  overflow: hidden;
  padding: 5px;
}
.tierlist__item {
  position: relative;
  display: flex;
  z-index: 10;
}
.tierlist__children {
  position: relative;
  z-index: 8;
  transition: all 300ms ease;
  transition: height 400ms ease;
  transform: translateY(-110%);
  opacity: 0;
}
.tierlist__children__expanded {
  transform: translateY(0);
  opacity: 1;
}
/* dropdown menu */
.dd ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dd a {
  color: var(--dd-text-color);
  text-decoration: none;
  cursor: pointer;
}

.dd .dropdown {
  position: absolute;
  top: 38px;
  width: 300px;
  background-color: var(--dd-bg);
  padding: 10px;
  border: var(--dd-border);
  border-radius: var(--dd-border-radius);
  z-index: 9983;
  overflow: hidden;
  transition: height var(--dd-speed) ease;
}
.dd .nav-item {
  width: calc(var(--dd-nav-size) * 0.8);
}
.dd .nav-item > a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dd .menu-item {
  width: 280px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--dd-border-radius);
  transition: background var(--dd-speed);
  padding: 0.5rem;
}
.dd .menu-item:hover {
  background-color: #525357;
}
.dd .icon-button {
  --button-size: calc(var(--dd-nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;
}

.dd .icon-button:hover {
  filter: brightness(1.2);
}
.dd .icon-right {
  margin-left: auto;
}
.dd .menu {
  width: 280px;
  margin: 0 auto;
}
.dd .menu-item .icon-button {
  margin-right: 0.5rem;
}
/* CSSTransition classes  */
.dd .menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.dd .menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--dd-speed) ease;
}
.dd .menu-primary-exit {
  position: absolute;
}
.dd .menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--dd-speed) ease;
}

.dd .menu-secondary-enter {
  transform: translateX(110%);
}
.dd .menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--dd-speed) ease;
}
.dd .menu-secondary-exit {
}
.dd .menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--dd-speed) ease;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

/* scrollbar */
/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: rgba(239, 237, 239, 0.54) #171717;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: #171717;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(239, 237, 239, 0.54);
  border-radius: 10px;
}

.Mui-disabled {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.4) !important;
}
.MuiAutocomplete-loading {
  color: rgba(239, 237, 239, 0.87) !important;
}

.MuiStepLabel-iconContainer > svg.Mui-active {
  color: #262626 !important ;
}
.MuiStepLabel-label {
  color: rgba(239, 237, 239, 0.54) !important ;
}
.MuiStepLabel-label.Mui-active {
  color: #fff !important ;
}

.MuiTimelineItem-positionRight::before {
  content: none !important;
}

/* font icon picker overrides */
.rfipbtn__current,
.rfipdropdown__selector,
.rfipsearch__input,
.rfipicons__cp,
.rfipicons__ibox {
  background: #262626 !important;
}
.rfipicons__tp,
.rfipicons__cp,
.rfipsearch__input {
  color: #fff !important;
}

/* menu */
a {
  /* all: inherit; */
  padding: 0;
}
.mui-menu-popover {
  z-index: 1400;
}

/* execute css */
.cmd-exec-ctn {
  background: rgba(38, 38, 38, 0.6);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

/* wizard step active color */
.MuiStepIcon-root.Mui-active > circle {
  color: #ff914c !important;
}
.MuiStepIcon-root.Mui-active > text {
  fill: #000 !important;
}

/* breadcrumbs */
.breadcrumbs:hover {
  color: #fff;
}

/* alignment */
.align {
  display: flex;
  align-items: center;
}
.align > * {
  margin: 0 5px;
}

/* grid */
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
}

/* curb appeal */
.overlay {
  position: relative;
  z-index: 10;
}
.overlay::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
  height: 100%;
}

/* google charts bug with flickering */
svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

/* table */
.InovuaReactDataGrid__body {
  overflow: init !important;
}

/* character selection */
.char {
  border-radius: 10px;
  display: flex;
  height: 600px;
  width: 1200px;
  margin: auto;
  background: #171717;
}
.char--select {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100px;
  background: green;
  display: flex;
  flex-direction: column;
}
.char--select-char {
  height: 150px;
  position: relative;
  background-color: #ff914c;
  background-size: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/crew/auditor.png");
}
.char--select-char:hover::after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.5);
}
.char--select-char:nth-child(1) {
  background-image: url("../assets/crew/admin.png");
}
.char--select-char:nth-child(2) {
  background-image: url("../assets/crew/engineer.png");
}
.char--select-char:nth-child(3) {
  background-image: url("../assets/crew/operator.png");
}
.char--select-char:nth-child(4) {
  background-image: url("../assets/crew/auditor.png");
}
.char--select-char:nth-child(1n) {
  border-top: 2px solid #000;
}
.char--select-char:first-child {
  border-top-left-radius: 10px;
  border: none;
}
.char--select-char:last-child {
  border-bottom-left-radius: 10px;
}
.char--img {
  border-left: 2px solid #000;
  height: 600px;
  position: relative;
  width: 598px;
  background-image: url("../assets/crew/auditor.png");
  background-size: 600px;
  background-position: center;
  overflow: hidden;
}
.char--name {
  position: absolute;
  bottom: 0;
  text-align: center;
  right: -50px;
  font-family: "ECHELONONE";
  left: 5%;
  font-size: 45px;
  font-weight: bold;
  background: linear-gradient(
    180deg,
    rgba(132, 42, 197, 0.9) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  clip-path: polygon(9% 0%, 100% 0%, 100% 100%, 0% 100%);
  display: grid;
  grid-template-columns: 1fr;
}

@keyframes stack {
  0% {
    opacity: 0;
    transform: translateX(-50%);
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
  }
  60% {
    opacity: 0.5;
    transform: translateX(50%);
  }
  80% {
    transform: none;
    opacity: 1;
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes glitch {
  0% {
    text-shadow: -2px 3px 0 red, 2px -3px 0 blue;
    transform: translate(var(--glitch-translate));
  }
  2% {
    text-shadow: 2px -3px 0 red, -2px 3px 0 blue;
  }
  4%,
  100% {
    text-shadow: none;
    transform: none;
  }
}

.char--name span {
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;
}
.char--name p {
  position: relative;
  top: -10px;
  font-size: 18px;
  margin: 0 60px;
  font-family: "Exo";
}

.char--name span:nth-child(odd) {
  --glitch-translate: 8px;
}
.char--name span:nth-child(even) {
  --glitch-translate: -8px;
}

.char--info {
  padding: 0 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #fff;
  width: 550px;
  background: #171717;
  text-align: left;
}
.char--info ul {
  height: 150px;
}
.char--info ul > li {
  list-style-image: url(../assets/icons/emblem.svg);
  font-size: 45px;
  line-height: 20px;
  margin-top: 10px;
}
.char--info ul > li span {
  font-size: 15px;
  line-height: 20px;
  vertical-align: middle;
  margin-left: 10px;
  font-weight: bold;
}
.char--info-form {
  display: flex;
  flex-direction: column;
}
.char--info-form > * + * {
  margin-top: 10px;
}
.char--info-form > button {
  height: 50px;
  margin-top: 20px;
}

/* chatbot */
.rsc-float-button {
  /* position: relative; */
  background: transparent !important;
  width: 100px;
  height: 60px;
}
.rsc-float-button:after {
  content: "";
  position: absolute;

  inset: 0;
  background-image: url("../assets/crew/bot.png");
  background-size: 100px;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 2;
}
.rsc-ts-bubble {
  max-width: 70%;
}
.rsc-float-button > svg {
  display: none;
}
.rsc-ts-image {
  height: 50px;
}

/* logo animation */
#ehWrypZQTqz2_ts {
  animation: ehWrypZQTqz2_ts__ts 2400ms linear infinite normal forwards;
}
@keyframes ehWrypZQTqz2_ts__ts {
  0% {
    transform: translate(301.001209px, 299.923042px) scale(1, 1);
  }
  0.416667% {
    transform: translate(301.001209px, 299.923042px) scale(1, 1);
  }
  50% {
    transform: translate(301.001209px, 299.923042px) scale(0.85, 0.85);
  }
  100% {
    transform: translate(301.001209px, 299.923042px) scale(1, 1);
  }
}
#ehWrypZQTqz8_tr {
  animation: ehWrypZQTqz8_tr__tr 4400ms linear infinite normal forwards;
}
@keyframes ehWrypZQTqz8_tr__tr {
  0% {
    transform: translate(0.500125px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(0.500125px, 0px) rotate(-720deg);
  }
}

/* nav icons animations */
@keyframes MoveUpDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-100px);
  }
}
@keyframes MoveDownUp {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(100px);
  }
}

#archive-svg-icon:hover .group {
  animation: MoveUpDown 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}
#archive-svg-icon:hover .group2 {
  animation: MoveDownUp 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
}

/* shadows  */
.shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
}
.glowborder {
  box-shadow: inset 0 0 1px #fff,
    /* inner white */ inset 0px 0 1px rgba(169, 38, 250, 0.3),
    /* inner left magenta short */ inset -0px 0 1px rgba(169, 38, 250, 0.3),
    /* inner right cyan short */ inset 0px 0 1px rgba(169, 38, 250, 0.3),
    /* inner left magenta broad */ inset -0px 0 1px rgba(169, 38, 250, 0.3),
    /* inner right cyan broad */ 0 0 0px #fff,
    /* outer white */ -1px 0 15px rgba(169, 38, 250, 0.3),
    /* outer left magenta */ 1px 0 15px rgba(169, 38, 250, 0.3);
}
.redglowborder {
  box-shadow: inset 0 0 1px #fff,
    /* inner white */ inset 0px 0 1px rgba(250, 38, 38, 0.3),
    /* inner left magenta short */ inset -0px 0 1px rgba(250, 38, 38, 0.3),
    /* inner right cyan short */ inset 0px 0 1px rgba(250, 38, 38, 0.3),
    /* inner left magenta broad */ inset -0px 0 1px rgba(250, 38, 38, 0.3),
    /* inner right cyan broad */ 0 0 0px #fff,
    /* outer white */ -1px 0 15px rgba(250, 38, 38, 0.3),
    /* outer left magenta */ 1px 0 15px rgba(250, 38, 38, 0.3);
}

/* user cards */
.user-card-tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  overflow: hidden;
  transition: 300ms;
  cursor: pointer;
  position: relative;
}

.user-card-tile .user-card-tile__name {
  font-size: 16px;
  width: 180px;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.user-card-tile .user-card-tile__role {
  font-size: 12px;
  font-family: "ECHELONONE";
}
.user-card-tile__options {
  position: absolute;
  bottom: 0;
  height: 50px;
  top: -10%;
  left: 40%;
  transform: translate(-50%, -50%) rotateX(-90deg);
  opacity: 0;
}
.user-card-tile__options > * {
  box-shadow: -2px 10px 9px rgba(0, 0, 0), 0 1px 2px rgba(0, 0, 0, 0.24) !important;
}
.user-card-tile__options > * + * {
  margin-left: 10px;
}
@keyframes tilt-back {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(45deg);
  }
}
@keyframes tilt-forward {
  0% {
    transform: translateY(30px) rotateX(-45deg);
    opacity: 1;
  }
  50% {
    transform: translateY(-5px) rotateX(-45deg);
    opacity: 1;
  }
  60% {
    opacity: 1;
    transform: translateY(-5px) rotateX(-45deg);
  }
  100% {
    opacity: 1;
    transform: translateY(-5px) rotateX(0deg);
  }
}
/* @keyframes tilt-forward {
  0% {
    transform: rotateX(-45deg) translateY(10px);
    opacity: 1;
  }
  50% {
    transform: rotateX(0deg) translateY(30px);
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg) translateY(-5px);
  }
} */
.user-card-title-ctn:hover .user-card-tile {
  animation: tilt-back 100ms linear normal forwards;
}
.user-card-title-ctn:hover .user-card-tile__options {
  animation: tilt-forward 200ms 100ms linear normal forwards;
}

.user-card {
  position: relative;
  width: 300px;
  height: 300px;
  top: 2px;
  border-radius: 20px;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  cursor: pointer;
}
@media screen and (max-width: 1400px) {
  .grid {
    justify-content: center;
  }
}
@keyframes bgHover {
  0% {
    transform: translatex(0px) translatey(0px);
  }
  100% {
    transform: translatex(var(--hover-translate-x))
      translatey(var(--hover-translate-y));
  }
}
@keyframes tileShake {
  0%,
  30%,
  100% {
    transform: rotate(0);
  }
  10% {
    transform: rotate(1deg);
  }
  20% {
    transform: rotate(-1deg);
  }
}
.user-card:hover {
  animation: 2s tileShake linear 2s infinite;
}
.user-card:hover::after {
  position: absolute;
  content: "";
  inset: 0;
  width: 300px;
  height: 300px;
  left: -300px;
  top: -300px;
  border-radius: 20px;
  background: var(--user-card-bg);
  z-index: 31;
  --hover-translate-x: 300px;
  --hover-translate-y: 300px;
  animation: bgHover ease 0.1s forwards;
}
.user-card__bg {
  position: absolute;
  width: 937.35px;
  height: 600px;
  left: -461px;
  z-index: 10;
}
.user-card__backdrop {
  position: absolute;
  width: 246px;
  height: 500px;
  left: 54px;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
}
.user-card__name {
  position: absolute;
  width: 125px;
  height: 48px;
  left: 130px;
  top: 15px;
  color: rgba(255, 255, 255, 1);
  overflow: hidden;
  font-style: normal;
  text-overflow: ellipsis;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
  z-index: 30;
  font-family: "Exo";
}
.user-card__delete-btn {
  position: absolute;
  top: 2px;
  right: 0;
  z-index: 40;
}
.user-card__image {
  position: absolute;
  width: 324px;
  height: 324px;
  left: 50px;
  top: 43px;
  z-index: 30;
  transition: 0.5s;
}
.user-card:hover .user-card__image {
  transform: scale(1.1);
}
.user-card__class-block {
  position: absolute;
  width: 77px;
  height: 519px;
  top: 0;
  left: -23px;
  background: var(--user-card__class-color);
  z-index: 30;
}
.user-card__class {
  position: absolute;
  width: 230px;
  font-family: "ECHELONONE";
  height: 47px;
  left: -71.5px;
  top: 140px;
  text-align: center;
  color: var(--user-card__class-font-color);
  font-style: normal;
  font-size: 25px;
  font-weight: bold;
  line-height: 1.2;
  letter-spacing: 0px;
  text-decoration: none;
  text-transform: none;
  transform: rotate(270deg);
  z-index: 40;
}

/* astro store */
.astro-hero {
  /* Sizing */
  width: 100%;
  height: 300px;
  position: relative;
  /* Flexbox stuff */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* Text styles */
  text-align: center;
  color: white;
}
.astro-hero__bg {
  border-radius: 7px;
  position: absolute;
  z-index: 10;
  inset: 0;
  margin-bottom: 20px;
  /* Background styles */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* clip-path: polygon(
    100% 100%,
    0% 100%,
    0% 92.5%,
    1.25% 92.36%,
    2.5% 91.93%,
    3.75% 91.22%,
    5% 90.23%,
    6.25% 88.97%,
    7.5% 87.44%,
    8.75% 85.66%,
    10% 83.63%,
    11.25% 81.38%,
    12.5% 78.9%,
    13.75% 76.22%,
    15% 73.36%,
    16.25% 70.33%,
    17.5% 67.15%,
    18.75% 63.84%,
    20% 60.42%,
    21.25% 56.91%,
    22.5% 53.33%,
    23.75% 49.71%,
    25% 46.07%,
    26.25% 42.43%,
    27.5% 38.81%,
    28.75% 35.23%,
    30% 31.72%,
    31.25% 28.3%,
    32.5% 24.99%,
    33.75% 21.81%,
    35% 18.78%,
    36.25% 15.92%,
    37.5% 13.24%,
    38.75% 10.77%,
    40% 8.51%,
    41.25% 6.48%,
    42.5% 4.7%,
    43.75% 3.18%,
    45% 1.92%,
    46.25% 0.93%,
    47.5% 0.21%,
    48.75% -0.21%,
    50% -0.36%,
    51.25% -0.21%,
    52.5% 0.21%,
    53.75% 0.93%,
    55% 1.92%,
    56.25% 3.18%,
    57.5% 4.7%,
    58.75% 6.48%,
    60% 8.51%,
    61.25% 10.77%,
    62.5% 13.24%,
    63.75% 15.92%,
    65% 18.78%,
    66.25% 21.81%,
    67.5% 24.99%,
    68.75% 28.3%,
    70% 31.72%,
    71.25% 35.23%,
    72.5% 38.81%,
    73.75% 42.43%,
    75% 46.07%,
    76.25% 49.71%,
    77.5% 53.33%,
    78.75% 56.91%,
    80% 60.42%,
    81.25% 63.84%,
    82.5% 67.15%,
    83.75% 70.33%,
    85% 73.36%,
    86.25% 76.22%,
    87.5% 78.9%,
    88.75% 81.38%,
    90% 83.63%,
    91.25% 85.66%,
    92.5% 87.44%,
    93.75% 88.97%,
    95% 90.23%,
    96.25% 91.22%,
    97.5% 91.93%,
    98.75% 92.36%,
    100% 92.5%
  ); */
}
.astro-hero__bg2 {
  position: absolute;
  z-index: 11;
  inset: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../assets/crew/background.jpg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* clip-path: polygon(
    100% 0%,
    0% 0%,
    0% 92.5%,
    1.25% 92.36%,
    2.5% 91.93%,
    3.75% 91.22%,
    5% 90.23%,
    6.25% 88.97%,
    7.5% 87.44%,
    8.75% 85.66%,
    10% 83.63%,
    11.25% 81.38%,
    12.5% 78.9%,
    13.75% 76.22%,
    15% 73.36%,
    16.25% 70.33%,
    17.5% 67.15%,
    18.75% 63.84%,
    20% 60.42%,
    21.25% 56.91%,
    22.5% 53.33%,
    23.75% 49.71%,
    25% 46.07%,
    26.25% 42.43%,
    27.5% 38.81%,
    28.75% 35.23%,
    30% 31.72%,
    31.25% 28.3%,
    32.5% 24.99%,
    33.75% 21.81%,
    35% 18.78%,
    36.25% 15.92%,
    37.5% 13.24%,
    38.75% 10.77%,
    40% 8.51%,
    41.25% 6.48%,
    42.5% 4.7%,
    43.75% 3.18%,
    45% 1.92%,
    46.25% 0.93%,
    47.5% 0.21%,
    48.75% -0.21%,
    50% -0.36%,
    51.25% -0.21%,
    52.5% 0.21%,
    53.75% 0.93%,
    55% 1.92%,
    56.25% 3.18%,
    57.5% 4.7%,
    58.75% 6.48%,
    60% 8.51%,
    61.25% 10.77%,
    62.5% 13.24%,
    63.75% 15.92%,
    65% 18.78%,
    66.25% 21.81%,
    67.5% 24.99%,
    68.75% 28.3%,
    70% 31.72%,
    71.25% 35.23%,
    72.5% 38.81%,
    73.75% 42.43%,
    75% 46.07%,
    76.25% 49.71%,
    77.5% 53.33%,
    78.75% 56.91%,
    80% 60.42%,
    81.25% 63.84%,
    82.5% 67.15%,
    83.75% 70.33%,
    85% 73.36%,
    86.25% 76.22%,
    87.5% 78.9%,
    88.75% 81.38%,
    90% 83.63%,
    91.25% 85.66%,
    92.5% 87.44%,
    93.75% 88.97%,
    95% 90.23%,
    96.25% 91.22%,
    97.5% 91.93%,
    98.75% 92.36%,
    100% 92.5%
  ); */
}
.astro-hero__header {
  position: relative;
  z-index: 20;
  display: grid;
  grid-template-columns: 1fr;
}
.astro-hero h1 {
  position: relative;
  z-index: 21;
  /* Text styles */
  font-size: 7em;
  font-family: "ECHELONONE";
  /* Margins */
  margin-top: 20px;
  margin-bottom: 0;
  grid-row-start: 1;
  grid-column-start: 1;
  --stack-height: calc(100% / var(--stacks) - 1px);
  --inverse-index: calc(calc(var(--stacks) - 1) - var(--index));
  --clip-top: calc(var(--stack-height) * var(--index));
  --clip-bottom: calc(var(--stack-height) * var(--inverse-index));
  clip-path: inset(var(--clip-top) 0 var(--clip-bottom) 0);
  animation: stack 340ms cubic-bezier(0.46, 0.29, 0, 1.24) 1 backwards
      calc(var(--index) * 120ms),
    glitch 2s ease infinite 2s alternate-reverse;
}

.astro-hero h1:nth-child(odd) {
  --glitch-translate: 8px;
}
.astro-hero h1:nth-child(even) {
  --glitch-translate: -8px;
}

.astro-hero h2 {
  position: relative;
  z-index: 22;
  /* Text styles */
  font-size: 1.3em;
  font-family: "ECHELONONE";
  /* Margins */
  margin-top: -35px;
  margin-bottom: 80px;
}

.astro-hero__search {
  position: relative;
  z-index: 23;
  width: 50%;
  margin-top: -20px;
  min-width: 300px;
  margin: 0 auto;
  top: -50px;
}

.astro-grid {
  margin-top: 50px !important;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(auto-fit, minmax(100px, auto));
  gap: 30px;
}

.astro-sys-ov,
.astro-sys-install {
  margin: 20px;
}

.astro-sys-ov__header {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.astro-sys-ov__header h1 {
  margin: 0;
}
.astro-sys-ov__header h2 {
  margin: 0;
}
.astro-sys-ov__actions,
.astro-sys-install__actions {
  margin-top: 100px;
  display: flex;
  justify-content: space-around;
}
.astro-sys-ov__actions > * + *,
.astro-sys-install__actions > * + * {
  margin-left: 10px;
}
.astro-sys-ov__actions button,
.astro-sys-install__actions button {
  width: 70%;
}

.astro-card {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid white;
  overflow: hidden;
}
/* .astro-card:hover::after {
  position: absolute;
  z-index: 10;
  content: "";
  inset: 0;
  border-radius: 5px;
  background: rgba(71, 11, 98, 0.3);
  --hover-translate-x: 100%;
  --hover-translate-y: 100%;
  animation: bgHover ease 0.2s forwards;
} */
.astro-card__header {
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
}

@keyframes animateGradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
.astro-card__hr {
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background: linear-gradient(to right, #a827fa, #ea6c4f, #a827fa);
  background-size: 200% 200%;
}
.astro-card:hover .astro-card__hr {
  animation: animateGradient 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* .astro-card:hover .astro-card__header span:nth-child(1) {
  animation: spin ease 0.5s forwards;
} */
.astro-card__header span:nth-child(1) {
  margin-left: 10px;
  font-weight: bold;
  font-size: 1.3em;
}
.astro-card__content {
  position: relative;
  z-index: 20;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  /* height: 150px; */
}
.astro-card__content p {
  overflow: hidden;
  height: 80%;
}

.three-grid-column {
  grid-template-columns: repeat(3, 1fr);
}
@media screen and (max-width: 900px) {
  .three-grid-column {
    grid-template-columns: 50% 50%;
  }
}

@media screen and (max-width: 576px) {
  .three-grid-column {
    display: grid;
    grid-template-columns: 100%;
  }
}

/* code editor */
/* .ace-editor-container {
  position: relative;
  z-index: 1400;
} */

.MuiPopover-root {
  z-index: 1500 !important;
}
.MuiPopover-root .MuiMenu-list {
  z-index: 9980 !important;
}
.MuiAutocomplete-popper {
  z-index: 9982 !important;
}
.MuiModal-root {
  z-index: 9981 !important;
}

/* box shadows for side drawer */
.right-shadow {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 50px; /* Adjust as needed */
  height: 100%;
  background: linear-gradient(
    to left,
    rgba(241, 3, 237, 0.989),
    rgb(255, 132, 0)
  ); /* Adjust colors as needed */
  filter: blur(10px); /* Adjust as needed */
  z-index: -1;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-11-17 21:56:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.e1-fab {
  --fab-size: 70px;
  --background: #f04242;
  --shadow: #f0424245;
  height: var(--fab-size);
  width: var(--fab-size);
  border-radius: calc(var(--fab-size) / 2);
  background: var(--background);
  position: fixed;
  bottom: -200vh;
  left: 50%;
  transition: 0.3s;
  box-shadow: 0 0 0 3px var(--shadow);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.e1-fab__active {
  bottom: 25px;
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
  overflow: hidden;
}

.e1-fab__active:hover:after {
  --fab-size: 70px;
  --background: #f04242;
  --shadow: #f0424245;
  clip-path: polygon(89% 0, 100% 0%, 75% 100%, 55% 100%);
  content: "";
  inset: 0;
  top: -20px;
  bottom: -50px;
  position: absolute;
  z-index: 1;
  border-radius: calc(var(--fab-size) / 2);
  animation: slide infinite 1s;
  background: #ffffff5b;
}
.ace_editor {
  padding-bottom: 200px;
}

/* common ui effects */
.floating {
  border-radius: 10px;
  box-shadow: 0 10px 30px -10px rgba(0, 0, 0, 0.9);
  padding: 10px;
}

/* user add css */
.player-ctn {
  border: 5px solid purple;
  border-radius: 12px;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),
    url("../assets/crew/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: #fff;
  height: 600px;
  width: 100%;
}
.selected-ctn {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.char-abilities {
  list-style: none;
  margin: 0;
  padding: 20px;
}
.char-ability {
  position: relative;
  margin-bottom: 40px;
  /* border-bottom: 2px solid #bb1fe2; */
}
.char-ability::before {
  content: "";
  position: absolute;
  inset: 0;
  bottom: -10px;
  background: linear-gradient(to left, transparent, #bb1fe24a);
  /* background-clip: border-box;
        border: 5px solid transparent; */
}
.char-ability img {
  transform: rotate(20deg);
  position: absolute;
  left: 0;
  height: 50px;
  width: 50px;
  z-index: 90;
}
.char-ability strong {
  position: relative;
  z-index: 100;
  font-size: 1.5rem;
  margin-left: 65px;
  font-family: "EXO";
}
.char-ability p {
  position: relative;
  z-index: 100;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  margin-left: 65px;
  max-width: 103px;
}

.char-img__ctn {
  flex: 1;
  text-align: center;
}
.char-img__img {
  position: relative;
  width: 100%;
}
.char-info {
  margin-top: 10%;
  width: 20%;
  padding-right: 15px;
}
.char-name {
  padding: 0;
  margin: 0;
  font-size: 20px;
  letter-spacing: 5px;
  font-family: "ECHELONONE";
}

.player-pool {
  position: absolute;
  bottom: -120px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 10px;
  z-index: 100;
  flex-direction: row;
}
.player {
  position: relative;
  display: flex;
  width: 100px;
  height: 150px;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  background: grey;
  border: 3px solid purple;
  border-radius: 8px;
  overflow: hidden;
}
.player:after {
  content: "";
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.55);
}
.player.active:after {
  content: "";
  position: absolute;
  inset: 0;
  background: transparent;
}
.player-name {
  font-weight: bold;
  position: absolute;
  bottom: 0;
  font-weight: bold;
}
.player-img {
  width: 150%;
  position: absolute;
  inset: 0;
  left: -20%;
}
.player-img:after {
  content: "";
  position: absolute;
  inset: 0;
  left: -20%;
  width: 150%;
  background: rgba(0, 0, 0, 0.5);
}
.player-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 20px;
  left: 10px;
  right: 10px;
  /* height: 100px;
             width: 300px; */
}
.btn {
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 50px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn a {
  position: absolute;
  top: 0;
  /* left: 0; */
  width: 100%;
  /* height: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 10px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  font-weight: 400px;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}
.btn:hover a {
  letter-spacing: 3px;
}
.btn a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(45deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}
.btn:hover a::before {
  transform: skewX(45deg) translate(200px);
}
.btn::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}
.btn:hover::before /*lightup button*/ {
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.btn::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}
.btn:hover::after /*lightup button*/ {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}
.btn.red::before, /*chnage 1*/
      .btn.red::after {
  background: #ff1f71;
  box-shadow: 0 0 5px #ff1f71, 0 0 15px #ff1f71, 0 0 30px #ff1f71,
    0 0 60px #ff1f71;
}
.btn.blue::before, /* 2*/
      .btn.blue::after {
  background: #2db2ff;
  box-shadow: 0 0 5px #2db2ff, 0 0 15px #2db2ff, 0 0 30px #2db2ff,
    0 0 60px #2db2ff;
}
.btn.green::before, /* 3*/
      .btn.green::after {
  background: #28dd46;
  box-shadow: 0 0 5px #28dd46, 0 0 15px #28dd46, 0 0 30px #28dd46,
    0 0 60px #28dd46;
}
.player-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: 600px;
  width: 400px;
  right: -100px;
  top: 100px;
  bottom: 0;
  transform-style: preserve-3d;

  transform: perspective(2000px) rotateX(-27deg) rotateY(-39deg) rotateZ(-4deg)
    translateZ(6px);
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 12px;
  box-shadow: 0 8px 32px 0 rgba(135, 31, 135, 0.67);
}

@keyframes slide-in-blurred-bottom {
  0% {
    filter: blur(40px);
    top: 200%;
    opacity: 0;
  }
  100% {
    filter: blur(0);
    top: 50%;
    opacity: 1;
  }
}
.player-form-ctn {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 109;
}
.player-form {
  position: fixed;
  display: flex;
  align-items: center;
  padding: 50px;
  border-radius: 7px;
  flex-direction: column;
  gap: 20px;
  background: #171717;
  width: 500px;
  height: 320px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: slide-in-blurred-bottom 0.6s cubic-bezier(0.23, 1, 0.32, 1) both;
  z-index: 110;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(5.2px);
}
.player-form-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.player-form-clear {
  position: absolute;
  top: 10px;
  right: 10px;
}

/* store item */

.store-item {
  background-color: #171717;
  width: 820px;
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  padding: 40px;
}
.store-item__tag {
  background-color: #ff711a;
  width: 80px;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  font-size: 16px;
  color: #000;
  align-self: flex-start;
}
.store-item__header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}
.store-item__header__title {
  color: #fff;
}
.store-item__header__title__name {
  font-size: 22px;
}
.store-item__header__title__tagline {
  font-size: 16px;
}
.store-item__header__tags {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: auto;
}
.store-item__header__tags > .store-item__tag {
  background-color: #28ebc0;
  color: #171717;
  width: 100%;
  padding: 5px 10px;
  border-radius: 20px;
  text-align: center;
  font-size: 15px;
}
.store-item__image {
  object-fit: cover;
  object-position: center center;
}
.store-item__body {
  color: #fff;
}
.store-item__body__content {
  min-height: 150px;
  max-height: 300px;
  overflow-y: scroll;
  color: #fff;
  font-size: 1rem;
}
.store-item__image {
  object-fit: cover;
  object-position: center top;
}
.store-item__footer {
  display: flex;
  margin-top: 20px;
}
.store-item__badges {
  display: flex;
  gap: 30px;
}
.store-item__badges__title {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.store-item__badges__group {
  display: flex;
  gap: 20px;
}
.store-item__badge {
  height: 60px;
  width: 60px;
  border-radius: 30px;
  background-color: #28ebc0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.store-item__badge_ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.store-item__badge_name {
  color: #fff;
  font-weight: bold;
  font-size: 15px;
}
.store-item__badge .MuiBadge-badge {
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  font-size: 15px;
}
.store-item__buttons {
  gap: 10px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  width: 400px;
}

/* ^^ store item */

/* simple loader  */
.simple-loader svg path,
.simple-loader svg rect {
  fill: #ff6700;
}

/* action button  */
.cssbuttons-io-button {
  /* background: #a370f0; */
  color: white;
  font-family: inherit;
  padding: 0.25em 1.2em;
  font-size: 15px;
  font-weight: 500;
  border-radius: 0.9em;
  border: none;
  letter-spacing: 0.05em;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 2.8em;
  display: flex;
  align-items: center;
}
.cssbutton-icon {
  color: #fff !important;
}
.cssbuttons-io-button:hover .cssbutton-icon {
  color: #ff914c !important;
  animation: pulsate-bck 0.5s ease-in-out infinite both;
}
.cssbuttons-io-button:hover .cssbutton-icon-danger {
  color: #ff6961 !important;
}
@keyframes pulsate-bck {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.loader {
  display: flex;
  align-items: center;
  justify-self: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  transition: 1s;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 25px;
  margin: 0 5px;
  animation-delay: 0.25s;
}

.bar:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}

/* checkbox  */
.checkbox-wrapper-12 {
  justify-self: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.checkbox-wrapper-12 > svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper-12 * {
  box-sizing: border-box;
}

.checkbox-wrapper-12 input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper-12 input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper-12 .cbx {
  width: 24px;
  height: 24px;
  top: calc(100px - 12px);
  left: calc(100px - 12px);
}

.checkbox-wrapper-12 .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  border: 2px solid #bfbfc0;
  border-radius: 50%;
}

.checkbox-wrapper-12 .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper-12 .cbx svg path {
  stroke: #262626;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}

.checkbox-wrapper-12 .cbx input:checked + label {
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper-12 .cbx input:checked + label + svg path {
  stroke-dashoffset: 0;
}

@-moz-keyframes splash-12 {
  40% {
    background: #28ebc0;
    box-shadow: 0 -18px 0 -8px #28ebc0, 16px -8px 0 -8px #28ebc0,
      16px 8px 0 -8px #28ebc0, 0 18px 0 -8px #28ebc0, -16px 8px 0 -8px #28ebc0,
      -16px -8px 0 -8px #28ebc0;
  }

  100% {
    background: #28ebc0;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: #28ebc0;
    box-shadow: 0 -18px 0 -8px #28ebc0, 16px -8px 0 -8px #28ebc0,
      16px 8px 0 -8px #28ebc0, 0 18px 0 -8px #28ebc0, -16px 8px 0 -8px #28ebc0,
      -16px -8px 0 -8px #28ebc0;
  }

  100% {
    background: #28ebc0;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: #28ebc0;
    box-shadow: 0 -18px 0 -8px #28ebc0, 16px -8px 0 -8px #28ebc0,
      16px 8px 0 -8px #28ebc0, 0 18px 0 -8px #28ebc0, -16px 8px 0 -8px #28ebc0,
      -16px -8px 0 -8px #28ebc0;
  }

  100% {
    background: #28ebc0;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #28ebc0;
    box-shadow: 0 -18px 0 -8px #28ebc0, 16px -8px 0 -8px #28ebc0,
      16px 8px 0 -8px #28ebc0, 0 18px 0 -8px #28ebc0, -16px 8px 0 -8px #28ebc0,
      -16px -8px 0 -8px #28ebc0;
  }

  100% {
    background: #28ebc0;
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

/* json view */
.json-view {
  color: #fff !important;
  --json-property: #ff914c !important;
  --json-index: #ff6961 !important;
  --json-number: #ff6961 !important;
  --json-string: #a827fa !important;
  --json-boolean: #28ebc0 !important;
  --json-null: #28ebc0 !important;
}

.json-view .jv-button {
  color: #fff !important;
}

/* react markdown  */
.react-markdown-custom h1 {
  font-size: 1.7rem;
}
.react-markdown-custom h2 {
  font-size: 1.5em;
}
.react-markdown-custom h3 {
  font-size: 1.3rem;
}
.react-markdown-custom h4 {
  font-size: 1.1rem;
}
.react-markdown-custom h5 {
  font-size: 1rem;
}
.react-markdown-custom a {
  color: #ff914c; /* Change this to your desired color */
}

.react-markdown-custom a:hover {
  color: #ff711a; /* Change this to your desired hover color */
}

/* sso */
.login__entra:hover {
  background: #75ace5 !important;
}

/* resource view */
.resourceTable {
  width: 100%;
  font-size: 16px;
  text-align: left;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.resourceTable th,
.resourceTable td {
  padding: 12px 15px;
  /* border: 1px solid #ddd; */
}

.resourceTable tr:nth-child(even) {
  background-color: #212121;
}
.resourceTable a {
  color: white; /* Normal state */
}

.resourceTable a:visited {
  color: white; /* Visited state */
}

.resourceTable a:hover {
  color: white; /* Hover state */
}

.resourceTable a:active {
  color: white; /* Active state */
}
/*
.resourceTable tr:hover {
  background-color: #ddd;
} */

.resourceTable ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.resourceTable ul li {
  padding: 5px 0;
}

.resourceTable table {
  width: 100%;
  border-collapse: separate;
}

.resourceTable table th,
.resourceTable table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
}

.resourceTable table th {
  background-color: #e9e9e9;
  font-weight: normal;
}

@media (max-width: 768px) {
  .resourceTable {
    font-size: 14px;
  }

  .resourceTable th,
  .resourceTable td {
    padding: 8px 10px;
  }
}

/* glowing circle */
.glowing-circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #28ebc0;
  -webkit-animation: glowing 1s ease-in-out infinite alternate;
  -moz-animation: glowing 1s ease-in-out infinite alternate;
  animation: glowing 1s ease-in-out infinite alternate;
}
@keyframes glowing {
  from {
    box-shadow: 0 0 2px #ffffffbc;
  }
  to {
    box-shadow: 0 0 10px #ffffffbc;
  }
}
/* checkbox */
.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.check:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(34, 50, 84, 0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.check:hover:before {
  opacity: 1;
}

.check:hover svg {
  stroke: #a827fa;
}

.cbx:checked + .check svg {
  stroke: #a827fa;
}

.cbx:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}

.cbx:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}

/* icon picker */
.iconPicker__iconItem.selected {
  color: #fff;
  background-color: #556ee6;
}
.iconPicker__iconItem:hover {
  color: #fff;
  background-color: #55e677;
}

/* radio buttons */
.radio-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-inputs > * {
  margin: 6px;
}

.radio-input:checked + .radio-tile {
  border-color: #ff914c;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #ff914c;
}

.radio-input:checked + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
  background-color: #ff914c;
  border-color: #ff914c;
}

.radio-input:checked + .radio-tile .radio-icon svg {
  fill: #ff914c;
}

.radio-input:checked + .radio-tile .radio-label {
  color: #ff914c;
}

.radio-input:focus + .radio-tile {
  border-color: #ff914c;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #ff721a3b;
}

.radio-input:focus + .radio-tile:before {
  transform: scale(1);
  opacity: 1;
}

.radio-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  min-height: 80px;
  border-radius: 0.5rem;
  border: 2px solid #eee;
  background-color: transparent;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease;
  cursor: pointer;
  position: relative;
}

.radio-tile:before {
  content: "";
  position: absolute;
  display: block;
  width: 0.75rem;
  height: 0.75rem;
  border: 2px solid #fff;
  background-color: transparent;
  border-radius: 50%;
  top: 0.25rem;
  left: 0.25rem;
  opacity: 0;
  transform: scale(0);
  transition: 0.25s ease;
}

.radio-tile:hover {
  border-color: #ff914c;
}

.radio-tile:hover:before {
  transform: scale(1);
  opacity: 1;
}

.radio-icon svg {
  width: 2rem;
  height: 2rem;
  fill: #fff;
}

.radio-label {
  color: #fff;
  transition: 0.375s ease;
  text-align: center;
  font-size: 13px;
}

.radio-input {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.holo-card {
  position: relative;
  width: 300px;
  height: 400px;
  background: linear-gradient(135deg, #1a1a1a 0%, #262626 100%);
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.holo-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    transparent 25%,
    rgba(255, 255, 255, 0.05) 50%,
    transparent 75%
  );
  background-size: 200% 200%;
  animation: shimmer 6s linear infinite;
  pointer-events: none;
}

@keyframes shimmer {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

/* ag-react-grid */
.overflow-visible {
  overflow: visible !important;
}
